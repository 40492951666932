import React from 'react';
import Banner from '../components/Banner';


const FoodService = () => {
    return(
        <div className="page_wrapper pp_wrap">
                <Banner
                    img={require('../assets/img/partner-banner.png')}
                    title="Payment Partner" />


            <div className="container mt-5 pt-5 mb-5 pb-5">
                <h2 className="text-center mb-4">Payment Partner</h2>

                <p>TravelCore introduces an innovative payment gateway designed specifically for the hospitality industry. They provide offline payment and credit support, addressing the unique multi invoicing/ Multiple Vendor management challenges faced by corporates in their day to day life. With our one stop payment aggregating system and credit options, HotPay simplifies financial transactions, allowing you to manage your bulk bookings and payments conveniently and efficiently.</p>


            </div>


        </div>
    )
}

export default FoodService;